import { DateTimeFormat, NumberFormat } from '@intlify/core-base'
import { createI18n } from 'vue-i18n'
import frLocale from './locales/fr.json'

const numberFormats: { fr: NumberFormat } = {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
      maximumFractionDigits: 0,
    },
    preciseCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'standard',
    },
    compactCurrency: {
      style: 'currency',
      currency: 'EUR',
      notation: 'compact',
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2,
    },
    rate: {
      minimumFractionDigits: 2,
    },
  },
}

const datetimeFormats: { fr: DateTimeFormat } = {
  fr: {
    long: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    short: {
      month: 'long',
      day: 'numeric',
    },
  },
}

const i18n = createI18n<false>({
  legacy: false,
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: {
    fr: frLocale,
  },
  numberFormats,
  datetimeFormats,
  missingWarn: false, // Removes warn log when missing key, don't hesitate to remove this if you want the warning
})

export default i18n
