<template>
  <div
    class="bg-gray-300 text-gray-900 min-h-screen"
    :class="{
      'custom-scrollbar': isCustomSidebarActive,
    }"
  >
    <RouterView v-slot="{ Component }">
      <Transition name="fade">
        <Suspense :timeout="0">
          <template #default>
            <component :is="Component" />
          </template>

          <template #fallback>
            <div class="bg-gray-800 text-white h-screen grid place-content-center text-center relative z-50">
              <p class="text-xl mb-8">{{ $t('app_loading') }}</p>

              <SpinnerIcon class="w-12 h-12 text-blue-300 m-auto" />
            </div>
          </template>
        </Suspense>
      </Transition>
    </RouterView>
  </div>
</template>

<script setup lang="ts">
import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { useAnalytics } from '@/config/composables/segment'
import { RouterView } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { storeToRefs } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import { EFeatureFlag, useFeatureFlags } from './config/composables/feature_flags'
import { useStonlyHelper } from '@/config/stonly.helpers'
import { useStore } from '@/config/composables/store'
import { computed } from 'vue'

const { user } = useAuth0()
const store = useStore()
const { projectId } = storeToRefs(store)
const { setAdditionalInfo, setUserEmail } = useStonlyHelper()
const { featureFlags } = useFeatureFlags()

if (process.env.NODE_ENV === 'production') {
  const { identify } = useAnalytics()

  if (user.value) {
    identify(user.value.email, {
      email: user.value.email,
    })

    Sentry.setUser({
      email: user.value.email,
    })

    setAdditionalInfo({
      user_email: user.value.email!,
    })

    setUserEmail(user.value.email!)
  }

  if (projectId.value) {
    setAdditionalInfo({
      project_id: projectId.value,
    })
  }
}

const isCustomSidebarActive = computed<boolean>(
  () => !featureFlags.find((flag) => flag.name === EFeatureFlag.ScrollbarQuickFix)?.value,
)
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
